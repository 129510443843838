<template>
  <div>
    <slot name="header" />
    <div ref="content">
      <slot />
    </div>
    <div v-if="content" ref="propContent" class="prop-content">
      <content-with-anchors :content="content" @content-ready="onContentReady" />
    </div>
    <slot name="footer" />
    <div
      v-if="isPreviewVisible"
      class="preview-container"
    >
    <span>Attachments preview:</span>
      <b-button-close 
        v-if="cancelable" 
        class="preview-container__cancel" 
        @click="handleCancel"
      >
        <b-icon-x />
      </b-button-close>
      <b-link 
        :href="linkMetaData.url" 
        target="_blank"
      >
        <b-card
          tag="article"
          class="mt-2 d-block position-static preview"
          :title="linkMetaData.title"
          :img-src="imageSrc"
          img-top
        >
          <p class="p-0 text-secondary">
            {{ linkMetaData.url }}
          </p>
        </b-card>
      </b-link>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Service from '@/config/service-identifiers';
import {
  BCard, BLink, BButtonClose, BIconX,
} from 'bootstrap-vue';
import { getLinkPreview, getUri } from '@core/utils/url-utils';
import ContentWithAnchors from '@core/components/string-utils/ContentWithAnchors.vue';

export default {
  name: 'AppendPreview',
  components: {
    BCard, BLink, ContentWithAnchors, BButtonClose, BIconX,
  },
  props: {
    /**
     * Optional.
     * If given, this content will be used to find the anchors.
     * Otherwise the slot will be used to find the anchors.
     */
    content: {
      type: String,
      default: null,
    },
    /**
     * If true, then the preview can be canceled
     */
    cancelable: Boolean,

    enabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      linkMetaData: null,
      imageSrc: null,
      // this is a very simple way to avoid the generation of new previews when update is called bevause `linkMetadata`
      // changed. If this is buggy, another option is to use a MutationObserver to observe changes only on the slot,
      // instead of using update.
      generatingPreview: false,

      requestCancelTokenSource: null,
      retryTimeoutId: null,

      isCancelled: false,
    };
  },
  computed: {
    isPreviewVisible() {
      return this.enabled && !this.isCancelled && (this.linkMetaData && (this.linkMetaData.title || this.imageSrc));
    },
  },
  mounted() {
    this.generatePreview(true);
  },
  updated() {
    if (this.generatingPreview) {
      return;
    }

    this.generatePreview(true);
  },
  methods: {
    async onContentReady() {
      // each time it receives a request it starts a one second delay and cancels the last one.
      if (this.contentChangedTimeoutId) {
        clearTimeout(this.contentChangedTimeoutId);
      }
      await this.$nextTick();
      this.contentChangedTimeoutId = setTimeout(() => {
        this.generatePreview(true, true);
      }, 1 * 1000);
    },
    getContentReference() {
      return this.content ? this.$refs.propContent : this.$refs.content;
    },
    async generatePreview(isFirstTry, cancelPrevious) {
      if (!this.enabled) {
        return;
      }

      if (this.linkMetaData && !cancelPrevious) {
        return;
      }

      if (cancelPrevious) {
        if (this.requestCancelTokenSource) {
          this.requestCancelTokenSource.cancel();
        }
        if (this.retryTimeoutId) {
          clearTimeout(this.retryTimeoutId);
          this.retryTimeoutId = null;
        }
      }

      // Deconstruction is necesary, as getElementsByTagName does not return a standard array.
      // const anchorWithHref = this.getContentReference().getElementsByTagName('a') == null ? [] : [...this.getContentReference().getElementsByTagName('a')]
      //   .find((anchor) => !!anchor.getAttribute('href'));
      const anchorWithHref = null;
      if (!anchorWithHref) {
        this.linkMetaData = null;
        this.previewURL = null;
        return;
      }

      this.isCancelled = false;
      this.$emit('link-found', anchorWithHref.getAttribute('href'));
      if (this.previewURL === anchorWithHref.getAttribute('href')) {
        return;
      }

      this.generatingPreview = true;
      this.linkMetaData = null;
      this.previewURL = anchorWithHref.getAttribute('href');
      await this.$nextTick();

      try {
        this.linkMetaData = await getLinkPreview(this.previewURL);

        const imageUrl = (this.linkMetaData.images && this.linkMetaData.images[0]) || null;

        if (imageUrl) {
          const corsFreeImageUrl = await getUri({
            url: 'getCORSData',
            params: { url: imageUrl },
            excludeBase: true,
          });

          this.requestCancelTokenSource = axios.CancelToken.source();

          const response = await this.$store.$service[Service.BackendClient].get(corsFreeImageUrl, {
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
            responseType: 'blob',
            cancelToken: this.requestCancelTokenSource.token,
          });

          this.imageSrc = URL.createObjectURL(response.data);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          this.generatingPreview = false;
          this.requestCancelTokenSource = null;
          return;
        }
        // fail silently and try once again in 5 seconds, but only once.
        if (isFirstTry) {
          this.retryTimeoutId = setTimeout(() => this.generatePreview(), 5 * 1000);
        }
      }
      await this.$nextTick();

      this.generatingPreview = false;
    },
    handleCancel() {
      this.isCancelled = true;
      this.$emit('cancelled');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.preview {
  img {
    max-height: 284px;
    object-fit: cover;
  }
}
.preview-container {
  position: relative;
  &__cancel {
    position: absolute;
    top: 5px;
    right: 5px;
    background: $white;
    opacity: 1;
    border-radius: 50%;
    border: 1px solid $secondary;
  }
}

.prop-content {
  opacity: 0;
  position: absolute;
  left: -999em;
}
</style>

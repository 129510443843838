export function removeDuplicates(array) {
  return [...new Set(array)];
}

/**
 * Tests if the given string is a valid html string.
 * @see https://stackoverflow.com/a/55585398/260389
 *
 * @param {String} string The string to be tested.
 * @returns true if it's a valid html string. false otherwise.
 */
export function isHtml(string) {
  const fragment = document.createRange().createContextualFragment(string);

  // remove all non text nodes from fragment
  fragment.querySelectorAll('*').forEach((el) => el.parentNode.removeChild(el));

  // if there is textContent, then not a pure HTML
  return !(fragment.textContent || '').trim();
}

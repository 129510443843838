<template>
  <!-- eslint-disable vue/no-v-html-->
  <component
    :is="tag"
    v-if="!processedHtml"
    ref="unprocessedContent"
    class="text-post"
    :class="{ 'plain-text-content': isContentPlainText }"
    v-html="content"
  />
  <component :is="processedHtml" v-else :class="{ 'plain-text-content': isContentPlainText }" />
</template>

<script>
import { removeDuplicates, isHtml } from '@/@core/utils/string-utils';
import { URL_REGEX } from '@/@core/utils/regex-utils';

export default {
  name: 'ContentWithAnchors',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    content: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      processedHtml: null,
    };
  },
  computed: {
    isContentPlainText() {
      return !isHtml(this.content);
    },
  },
  watch: {
    content() {
      this.processHtml();
    },
  },
  mounted() {
    this.processHtml();
  },
  methods: {
    async processHtml() {
      this.processedHtml = null;
      await this.$nextTick();

      const anchoredContent = [];
      // Before searching for urls hide all already processed anchor tags
      // and make sure they all open in a new window
      this.$refs.unprocessedContent?.getElementsByTagName('a').forEach((anchor) => {
        anchor.style.setProperty('display', 'none', 'important');
        anchor.setAttribute('target', '_blank');
        anchoredContent.push(anchor.innerText);
      });

      await this.$nextTick();

      let matches = this.$refs.unprocessedContent?.innerText.match(URL_REGEX);

      // Show all already processed anchor tags
      this.$refs.unprocessedContent?.getElementsByTagName('a')
        .forEach((anchor) => { anchor.style.setProperty('display', null); });

      if (!matches) {
        return;
      }

      matches = removeDuplicates(matches);

      // This next block has been added because sometimes 'innerText' does not
      // filter out the hidden anchors.
      anchoredContent.forEach((text) => {
        const index = matches.indexOf(text);
        if (index > -1) {
          matches.splice(index, 1);
        }
      });

      // Replace all urls with anchors
      this.processedHtml = {
        template: `<component is="${this.tag}">${
          matches.reduce((processedHtml, match) => {
            // urls from innerText have already been escaped.
            const escapedURL = match.replaceAll('&', '&amp;');
            const url = match.startsWith('http') || match.startsWith('//') ? match : `//${match}`;

            return processedHtml.replaceAll(escapedURL, `<a href="${url}" target="_blank">${escapedURL}</a>`);
          }, this.content)
        }</component>`,
      };

      this.$emit('content-ready');
    },
  },
};
</script>

<style lang="scss" scoped>
.plain-text-content {
  word-wrap: break-word;
  white-space: pre-wrap;
}
.text-post::v-deep img {
  max-width: 100% !important;
  height: auto !important;
}
</style>
